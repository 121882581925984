*{
  margin: 0;
  padding: 0;
}

.mainDiv {
  width: 100%;
  height: 100%;
}


