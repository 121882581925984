.projects {
    min-height: 100vh;
    max-height: 100%;
    max-width: 100%;
    background-image: linear-gradient(180deg, rgb(143, 132, 120), rgb(39, 38, 36));
}

.projectsContainer{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 50px;
}

.projectsHeader {
    text-align: center;
    color: rgb(40, 224, 40);
    margin-top: 50px;
    font-size: 40px
}

.projectContainer {
    display: flex;
    justify-content: space-evenly;
    flex-flow: wrap;

}

.project {
    margin-top: 30px;
    min-width: 800px;
    height: fit-content;
    border:  2px rgb(39, 24, 4) solid;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-flow: column;

    h2 {
        color: black;
        margin-top: 10px;
        font-size: 25px;
        text-align: center;
    }

    h3 {
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
    }
}



.projectPics {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-flow: wrap;

    img {
        border: 2px rgb(39, 24, 4) solid;
        border-radius: 5px;
    }
}

.projectBtn {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 200px;
    height: 75px;
    background-color: rgb(40, 224, 40);
    border: none;
    border-radius: 10px;
    margin: 10px;

}

.projectBtn:hover{

    background-color: darkgreen;
    cursor: pointer;
}


.exitBtn {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 200px;
    height: 75px;
    background-color: rgb(40, 224, 40);
    border: none;
    border-radius: 10px;
    margin: 50px;
}

.exitBtn:hover{

    background-color: darkgreen;
    cursor: pointer;
}