.home {
    min-height: 100vh;
    max-height: 100%;
    max-width: 100%;
    background-image: linear-gradient(180deg, rgb(143, 132, 120), rgb(39, 38, 36));
}

.homeHeader {
    margin-top: 50px;
    text-align: center;
    color: rgb(40, 224, 40);
}

.homeContent {
    margin-top: 100px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 30px;
}

.image {
    border: 2px rgb(39, 24, 4) solid;
    border-radius:  0px 0px 50px 50px;
    height: 500px;
    max-width: 400px;
    width: min-content;
    background-color: rgb(207, 207, 207);
}

img {
    max-height: 400px;
    max-width: 400px;
}

.imgDescription {
    text-align: center;
    color: rgb(39, 24, 4);
    margin-top: 25px;
}