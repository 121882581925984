.about {
    min-height: 100vh;
    max-height: 100%;
    max-width: 100%;
    background-image: linear-gradient(180deg, rgb(143, 132, 120), rgb(39, 38, 36)); 
}

.aboutHeader {
    text-align: center;
    color: rgb(40, 224, 40);
    margin-top: 50px;
}

.aboutMe {
    background-color: dodgerblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-top: 20px;
        margin-bottom: 50px;

    h2 {
        font-size: 35px;
        margin: 20px;
        color: green;
    }

    h3 {
        color: green;
        font-size: 25px;
    }

    ul {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        padding: 10px;
        margin: 20px;

        li {
            margin: 20px;
            list-style: inside;
            padding: 10px;
            font-size: 20px;
            font-weight: 500;
            border-radius: 10px;
        }

        li:nth-child(even){
            background-color: rgb(160, 235, 160);
        }

        li:nth-child(odd){
            background-color: rgb(167, 167, 167);
        }
    }

    p {
        font-size: 20px;
        font-weight: 500;
        margin: 20px;
        width: 75%;
    }

}

.education {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;

    .eduDiv {
        margin-left: 100px;
    }

    img {
        margin-left: 100px;
    }
}

.uni {
    display: flex;
    justify-content: flex-start;

    img {
        width: 75px;
        height: 75px;
    }
    
    font-size: 20px;
    font-weight: 500;
}

