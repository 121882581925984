.contact {
    min-height: 100vh;
    max-width: 100%;
    max-height: 100%;
    background-image: linear-gradient(180deg, rgb(143, 132, 120), rgb(39, 38, 36));
}

.contactHeader {
    text-align: center;
    color: rgb(40, 224, 40);
    margin-top: 50px;
}

.contactContent {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
}

.contactBox {
    width: 400px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    margin-top: 30px;
    border: 2px rgb(40, 224, 40) double;

    h2 {
        margin-top: 10px;
        font-size: 20px;
    }

    img {
        width: 50px;
        height: 50px;
        align-self: flex-start;
    }

    
}

.contactLink {
    display: flex;
    flex-direction: row;

    p{
        font-size: 20px;
    }

}

.indeed {
        min-width: 70px;
        height: 50px;
        align-self: flex-start;
        margin-left: 10px;
}

.emailLink {
    padding-top: 15px;
    display: flex;
    flex-direction: row;

    a {
        font-size: 20px;
        text-decoration: none;
        color: rgb(22, 179, 22);
        margin: 0 5px;
        font-weight: 700;
    }
    
}

.link {
    padding-top: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;

    a {
        text-decoration: none;
        color: rgb(22, 179, 22);
        margin: 0 5px;
        font-weight: 700;
        font-size: 20px;
    }
}

